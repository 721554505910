<template>
  <div class="h-100 mt-4 mb-4">
    <div class="mb-3">
      <div class="flex gap-5 mb-4 justify-content-between">
        <div class="card " style="flex: 1; border-radius: 8; padding: 2rem;">
          <h4 class="card-title mb-4">Нежелательные события за период</h4>
          <div
            v-for="(ineventByPeriod, i) in widgetData.ineventsByPeriod"
            :key="i"
            class="general-info-widget__list"
            :class="{ 'mb-4': i === 2 }"
          >
            <div :key="ineventByPeriod.title" class="general-info-widget__list-item">
              <div class="general-info-widget__list-title">
                <NextIcon class="text-primary mr-2" icon="cross" size="10px" />
                {{ ineventByPeriod.title }}
              </div>
              <div class="general-info-widget__list-value">
                <!-- <SpinLoader v-if="isLoading" class="w-16" /> -->
                {{ ineventByPeriod.value }}
                <div
                  v-if="ineventByPeriod.todayValue"
                  class="general-info-widget__list-percent text-danger"
                >
                  ({{ ineventByPeriod.todayValue }})
                </div>
                <div v-else class="general-info-widget__list-percent" />
              </div>
            </div>
          </div>
        </div>
        <div class="card p-4" style="flex: 2; border-radius: 8;">
          <div class="d-flex gap-3 pl-3">
            <NSelect
              v-model:value="year"
              :style="{
                width: '140px'
              }"
              size="large"
              placeholder="Выбрать год"
              :options="years"
              @change="changeYear"
            />
            <div class="w-8 mr-5 pt-2">
              <SpinLoader v-if="isLoading" />
            </div>

            <h4 class="card-title mb-0">Нежелательные события</h4>
          </div>

          <apexchart
            width="100%"
            height="300px"
            :options="chartOptions"
            :series="widgetData.inevents"
          />
        </div>
      </div>

      <!-- <div class="card" style="border-radius: 8; padding: 2rem;">
        <apexchart
          width="100%"
          height="250px"
          :options="chartOptions2"
          :series="widgetData.risksDynamics"
        />
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import api from '@/api'
import _ from 'lodash'
import moment from 'moment';
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import NextIcon from '@/components/widgets/simple-components/icon'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import { NSelect } from 'naive-ui';
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

const years = [
  { label: '2023', value: '2023', },
  { label: '2024', value: '2024', },
  { label: '2025', value: '2025', },
]

const isLoading = ref(false)

const widgetData = ref({
  inevents: [],
  ineventsByPeriod: [],
  risksDynamics: []
})

const year = ref('2025')

const chartOptions = {
  title: {
    // text: 'Нежелательные события',
    align: 'center',
    style: {
      display: 'none',
      fontSize: 20,
      fontWeight: 500
    }
  },
  chart: {
    id: 'vuechart-example',
    type: 'bar',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  tooltip: {
    style: {
      fontSize: 18
    }
  },
  colors: ['#d9505d', '#397cbf'],
  plotOptions: {
    bar: {
      columnWidth: '40%'
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: months
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return val.toFixed(0)
      }
    }
  }
}

const chartOptions2 = {
  title: {
    text: 'Динамика изменения общего уровня Рисков по МО в %',
    align: 'center',
    style: {
      fontSize: 20,
      fontWeight: 500
    }
  },
  chart: {
    id: 'vuechart-example2',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  tooltip: {
    style: {
      fontSize: 18
    }
  },
  colors: ['#397cbf'],
  plotOptions: {
    bar: {
      columnWidth: '40%'
    }
  },
  dataLabels: {
    enabled: false
  },
  yaxis: {
    max: 100,
    labels: {
      formatter: (value) => {
        return value.toFixed(0) + '%'
      },
    }
  }
  // xaxis: {
  //   categories: months
  // },
}

onMounted(() => {
  fetchData()
})

const fetchData = async () => {
  isLoading.value = true

  try {
    const { data: {
      ineventsByPeriod,
      inevents,
      risksDynamics,
    } } = await api.get('/inevent/dashboard', {
      params: {
        year: year.value
      }
    })

    widgetData.value = {
      ineventsByPeriod,
      inevents: inevents.map(({ label, data }) => ({ name: label, data })),
      risksDynamics: [{
        name: 'Значение',
        data: risksDynamics
      }],
    }
  } catch (error) {
    console.log('ERROR', error);
  } finally {
    isLoading.value = false
  }
}

const changeYear = (val) => {
  year.value = val
  fetchData()
}
</script>


<style lang="scss" scoped>
.general-info-widget {
  &__list {
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      font-size: 1.125rem;

      &::before {
        content: '';
        position: absolute;
        top: calc(100% - 8px);
        left: 0;
        width: 100%;
        border: 1px dashed #ddd;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-title {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 8px;
      font-weight: 300;
      background: #fff;
    }

    &-value {
      position: relative;
      display: flex;
      padding-left: 8px;
      font-weight: 500;
      background: #fff;
      z-index: 1;
    }

    &-percent {
      width: 30px;
      margin-left: 0.5rem;
      color: #aaa;
      text-align: right;
      white-space: nowrap;
    }
  }
}
</style>

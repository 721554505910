<template>
  <div>
    <component :is="component" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Header from './headers/Header.vue';
import Header66 from './headers/Header66.vue';

const isProd = location.origin === "https://vkkmed.ru"
const isDev = location.origin.includes('dev-front')

const component = computed(() => {
  const organizationId = JSON.parse(localStorage.user).organization.id

  if (isProd) {
    if (organizationId === 66) return Header66
  }

  if (isDev) {
    // на дев 43 = на прод 66 организация
    if (organizationId === 43) return Header66
  }

  return Header
})
</script>

<style lang="scss">
.pdf-header {
  height: 68px;
  padding-bottom: 1.75rem; // 28px

  &__logo {
    height: 1.125rem; // 18px
  }

  &__line {
    height: .25rem; // 4px
    background-color: #1DC8A9;
  }
}
</style>
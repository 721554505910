<template>
  <div class="general-info-widget card card-body h-100">
    <div class="flex justify-content-between">
      <h4 class="card-title mb-3">Общая информация</h4>
       <div>
        <NPopover>
          <template #trigger>
            <NButton text class="mr-3">
              <RouterLink to="/summary-report">
                <NIcon size="24" color="#37c4a3">
                  <Stack2 />
                </NIcon>
              </RouterLink>
            </NButton>
          </template>
          <span>Сводный отчёт</span>
        </NPopover>

        <NPopover trigger="click" placement="bottom">
          <template #trigger>
            <NButton text>
              <NIcon size="24" color="#37c4a3">
                <Settings />
              </NIcon>
            </NButton>
          </template>
          <div style="width: 1100px;" class="p-3">
            <h6 class="mb-3">Дашборды</h6>
            <div v-for="widget in widgetVisibility" :key="widget.name" class="mb-2 flex align-items-center" style="font-size: 16px;">
              <NSwitch v-model:value="widget.show" />
              <div class="ml-2">{{ widget.name }}</div>
            </div>

            <h6 class="mt-4">Показатели</h6>
            <div v-if="parameters.length > 0" style="max-height: 500px; overflow-y: auto;">
              <div v-for="parameter in parameters" :key="parameter.name" class="mb-3 flex align-items-center" style="font-size: 16px;">
                <NSwitch v-model:value="parameter.show" />
                <span class="ml-2">
                  {{ parameter.name }}
                  <span style="color: #1CC8AB;">{{ parameter.term_name }}</span>
                  ({{ parameter?.medtype?.text }})
                </span>
              </div>
            </div>
            <SpinLoader v-else class="w-16" />
          </div>
        </NPopover>
      </div>
    </div>
    <div class="h-100">
      <NextAlert v-if="isError && !isLoading" type="error" title="Произошла ошибка." :text="isError" />
      <template v-else>
        <div
          v-for="(list, i) in widgetData"
          :key="i"
          class="general-info-widget__list"
          :class="{ 'mb-4': i + 1 !== widgetData.length }"
        >
          <h5 class="text-secondary text-base">{{ list.title }}</h5>
          <div v-for="item in list.items" :key="item.title" class="general-info-widget__list-item">
            <div class="general-info-widget__list-title">
              <NextIcon class="text-primary mr-2" icon="cross" size="10px" />
              {{ item.title }}
            </div>
            <div class="general-info-widget__list-value">
              <SpinLoader v-if="isLoading" class="w-16" />
              <template v-else>
                {{ item.value }}
                <div
                  v-if="item.percent"
                  class="general-info-widget__list-percent"
                  :class="{ [`text-${item.color}`]: item.color }"
                >
                  {{ item.percent }}%
                </div>
                <div v-else class="general-info-widget__list-percent">—</div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import NextIcon from '@/components/widgets/simple-components/icon'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import { NButton, NIcon, NPopover, NSwitch } from 'naive-ui'
import { Settings, Stack2 } from '@vicons/tabler'
import { RouterLink } from 'vue-router'

export default {
  components: { NextAlert, SpinLoader, NextIcon, NIcon, Settings, NButton, NPopover, NSwitch, Stack2, RouterLink },
  props: {
    widgetVisibility: {
      type: Array,
      default: () => []
    },
    parameters: {
      type: Array,
      default: () => []
    }
  },
  emits: ['toggle-widgets'],
  data() {
    return {
      isLoading: true,
      isError: false,
      widgetData: {
        general: {
          title: null,
          items: {
            processes: {
              title: 'Всего разделов',
              value: 0
            },
            requirements: {
              title: 'Всего требований',
              value: 0
            },
            checkedRequirements: {
              title: 'Проверено требований',
              value: 0
            }
          }
        },
        required: {
          title: 'Обязательные требования',
          items: {
            passed: {
              title: 'Всего соответствий',
              value: 0,
              percent: 0,
              color: 'primary'
            },
            unpassed: {
              title: 'Всего несоответствий',
              value: 0,
              percent: 0,
              color: 'danger'
            }
          }
        },
        optional: {
          title: 'Дополнительные требования',
          items: {
            passed: {
              title: 'Всего соответствий',
              value: 0,
              percent: 0,
              color: 'primary'
            },
            unpassed: {
              title: 'Всего несоответствий',
              value: 0,
              percent: 0,
              color: 'danger'
            }
          }
        }
      },
    }
  },
  mounted() {
    this.fetchProcesses()
  },
  methods: {
    async fetchProcesses() {
      try {
        this.isLoading = true
        this.isError = false

        const res = await this.$api.get('/process/formatted')

        if (res.data.ok === 1) {
          this.prepareData(res.data.data)
        } else {
          this.isError = res.data.message
        }
      } catch (e) {
        this.isError = e.message
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    prepareData(processes) {
      if (!Array.isArray(processes)) return

      const {
        all,
        checked,
        requiredAll,
        requiredPassed,
        requiredUnpassed,
        optionalAll,
        optionalPassed,
        optionalUnpassed
      } = processes.reduce(
        (p, c) => {
          p.all += c?.requirements_count?.all || 0
          p.checked += c?.requirements_count?.checked || 0

          // Обазятельные требования
          p.requiredAll += c?.required_requirements_count?.passed + c?.required_requirements_count?.unpassed || 0
          p.requiredPassed += c?.required_requirements_count?.passed || 0
          p.requiredUnpassed += c?.required_requirements_count?.unpassed || 0

          // Необязательные требования
          p.optionalPassed += c?.optional_requirements_count?.passed || 0
          p.optionalUnpassed += c?.optional_requirements_count?.unpassed || 0
          p.optionalAll += c?.optional_requirements_count?.passed + c?.optional_requirements_count?.unpassed || 0

          return p
        },
        {
          all: 0,
          checked: 0,
          requiredAll: 0,
          requiredPassed: 0,
          requiredUnpassed: 0,
          optionalAll: 0,
          optionalPassed: 0,
          optionalUnpassed: 0
        }
      )

      this.widgetData.general.items.processes.value = processes.length
      this.widgetData.general.items.requirements.value = all
      this.widgetData.general.items.checkedRequirements.value = checked

      this.widgetData.required.items.passed.value = requiredPassed
      this.widgetData.required.items.passed.percent = this.$helper.toPercent(requiredAll, requiredPassed)

      this.widgetData.required.items.unpassed.value = requiredUnpassed
      this.widgetData.required.items.unpassed.percent = this.$helper.toPercent(requiredAll, requiredUnpassed)

      this.widgetData.optional.items.passed.value = optionalPassed
      this.widgetData.optional.items.passed.percent = this.$helper.toPercent(optionalAll, optionalPassed)

      this.widgetData.optional.items.unpassed.value = optionalUnpassed
      this.widgetData.optional.items.unpassed.percent = this.$helper.toPercent(optionalAll, optionalUnpassed)
    }
  }
}
</script>

<style lang="scss" scoped>
.general-info-widget {
  &__list {
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      font-size: 1.125rem;

      &::before {
        content: '';
        position: absolute;
        top: calc(100% - 8px);
        left: 0;
        width: 100%;
        border: 1px dashed #ddd;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-title {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 8px;
      font-weight: 300;
      background: #fff;
    }

    &-value {
      position: relative;
      display: flex;
      padding-left: 8px;
      font-weight: 500;
      background: #fff;
      z-index: 1;
    }

    &-percent {
      width: 48px;
      margin-left: 0.5rem;
      color: #aaa;
      text-align: right;
      white-space: nowrap;
    }
  }
}
</style>

import api from '@/api'

const endpoint = 'checklists/'

let checklistStatuses = []
checklistStatuses[1] = 'На проверке'
checklistStatuses[2] = 'Проверка завершена'
checklistStatuses[3] = 'Подписан'

const checklist = {
  state: {
    checklist: {},
    organizationChecklists: [],
    dontMatchRequirements: [],
    allStatuses: [],
    allAssurance: []
  },
  getters: {
    organizationChecklists(state) {
      return state.organizationChecklists
    },
    dontMatchRequirements(state) {
      return state.dontMatchRequirements
    },
    checklist(state) {
      return state.checklist
    },
    checklistAssurance(state) {
      return state.checklist.assurance ? state.checklist.assurance : false
    },
    checklistStatuses(state) {
      return state.allStatuses
    },
    checklistStatus(state) {
      return { text: state.checklist.status, id: state.checklist.status_id }
    },
    allAssurance(state) {
      return state.allAssurance
    }
  },
  mutations: {
    checklist_change(state, payload) {
      state.checklist = {
        ...state.checklist,
        ...payload
      }
    },
    set_all_statuses(state, payload) {
      state.allStatuses = payload
    },
    set_organization_checklists(state, payload) {
      state.organizationChecklists = payload
    },
    checklist_finish(state) {
      state.checklist.status = 2
    },
    checklist_delete(state) {
      state.checklist = {}
    },
    checklist_update_current_checklist(state, payload) {
      state.checklist = payload
    },
    dontmatch_change(state, payload) {
      state.dontMatchRequirements = payload
    },
    checklist_sign(state, payload) {
      state.checklist.status_id = 3
      state.checklist.status = checklistStatuses[3]
      state.checklist.sign_date = payload.sign_date
    },
    checklist_refresh_assurance(state, payload) {
      state.allAssurance = payload
    },
    checklist_change_assurance(state, payload) {
      state.checklist.assurance_id = payload.id
      state.checklist.assurance = payload
    }
  },
  actions: {
    async CHECKLIST_CHANGE_ASSURANCE(context, payload) {
      let result = await api.post(endpoint + 'change-assurance', payload)
      if (result.data.ok === 1) {
        context.commit('checklist_change_assurance', result.data.data)
      }
      return result
    },
    async CHECKLIST_REFRESH_ALL_ASSURANCE(context) {
      return await api.get('assurances/get-all').then((res) => {
        if (res.data.ok === 1) {
          context.commit('checklist_refresh_assurance', res.data.data)
        }
      })
    },
    async CHECKLIST_GET(context, payload) {
      return await api.get(endpoint + 'get?id=' + payload)
    },
    async CHECKLIST_SIGN(context, payload) {
      return api.post(endpoint + 'sign', { id: payload.id })
    },
    CHECKLIST_SET_ORGANIZATION_CHECKLISTS(context, payload) {
      context.commit('set_organization_checklists', payload)
    },
    CHECKLIST_GET_ORGANIZATION_CHECKLISTS(context, payload) {
      return api.get(
        endpoint +
          'get-organization-checklists?' +
          'offset=' +
          payload.offset +
          '&limit=' +
          payload.limit +
          '&date_from=' +
          payload.startDate +
          '&date_to=' +
          payload.stopDate +
          '&status=' +
          payload.status +
          '&sort_type=' +
          payload.sort.type +
          '&sort_direction=' +
          payload.sort.direction
      )
    },
    CHECKLIST_REFRESH_STATUSES(context) {
      api.get(endpoint + 'get-all-statuses').then((res) => {
        if (res.data.ok === 1) {
          context.commit('set_all_statuses', res.data.data)
        }
      })
    },
    CHECKLIST_REFRESH_DONT_MATCHES(context, medId) {
      api.get(endpoint + 'take-dont-match?medId=' + medId).then((res) => {
        if (res.data.ok === 1) {
          context.commit('dontmatch_change', res.data.data)
        }
      })
    },
    CHECKLIST_GET_BY_NUMBER(context, number) {
      return api.get(endpoint + 'get-by-number?number=' + number)
    },
    CHECKLIST_REFRESH_BY_NUMBER(context, number) {
      api.get(endpoint + 'get-by-number?number=' + number).then((res) => {
        if (res.data.ok === 1) {
          context.commit('checklist_change', res.data.data)
        }
      })
    },
    async CHECKLIST_CREATE(context, payload) {
      let result = await api.post(endpoint + 'create', payload)
      if (result.data.ok === 1) {
        context.commit('checklist_change', result.data.data)
      }
      return result
    },
    async CHECKLIST_GET_AUDITION_TYPES() {
      return api.get(endpoint + 'get-audition-types')
    },
    async CHECKLIST_UPDATE_CURRENT_CHECKLIST(context, payload) {
      return api.get(endpoint + 'get?id=' + payload).then((res) => {
        if (res.data.ok === 1) {
          context.commit('checklist_update_current_checklist', res.data.data)
        }
      })
    },
    async CHECKLIST_FINISH(context, payload) {
      let result = await api.post(endpoint + 'finish', payload)
      if (result.data.ok === 1) {
        context.commit('checklist_finish')
        return result
      }
    },
    async CHECKLIST_DELETE(context, payload) {
      let result = await api.post(endpoint + 'delete' + '?id=' + payload)

      if (result.data.ok === 1) {
        context.commit('checklist_delete')
        context.dispatch('ALERT_CREATE', { text: 'Успешно', type: 'success' })
        return result
      } else {
        context.dispatch('ALERT_CREATE', { text: result.data.message, type: 'error' })
      }
    },
    async CHECKLIST_PLAN_DATE_EDIT(context, payload) {
      let result = await api.post(endpoint + 'change-plan-date', payload)

      if (result.data.ok === 1) {
        context.commit('checklist_change', result.data.data)
      }
      return result
    },
    async CHECKLIST_FINISH_DATE_EDIT(context, payload) {
      let result = await api.post(endpoint + 'change-finish-date', payload)

      if (result.data.ok === 1) {
        const checklist = {
          ...context.state.checklist,
          finish_date: result.data.data.finish_date
        }
        
        context.commit('checklist_change', checklist)
      }
      return result
    },
  }
}
export default checklist

<script setup>
import { ref, watch } from 'vue'
import { NButton, NDatePicker } from 'naive-ui'

import Audition from './Audition'
import Actions from './Actions.vue'
import Incidents from './Incidents.vue'
import Parameters from './Parameters.vue'

const defaultRange = [
  new Date('2024-01-01T00:00:00').getTime(), // Начало 2024 года
  new Date('2024-12-31T23:59:59').getTime() // Конец 2024 года
]
const format = 'dd.MM.yyyy'

const range = ref(defaultRange)
const isResetDisabled = ref(true)

watch(
  () => range.value,
  (newRange) => {
    if (newRange[0] !== '1704056400000' || newRange[1] !== '1735678799000') {
      isResetDisabled.value = false
    }

    if (newRange[0] === '1704056400000' && newRange[1] === '1735678799000') {
      isResetDisabled.value = true
    }
  }
)

const reset = () => {
  range.value = defaultRange
}
</script>

<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__heading">Сводный отчёт</div>
        <div class="flex align-items-baseline">
          <NDatePicker v-model:value="range" type="daterange" :format="format" class="mr-3" />
          <NButton size="small" secondary :disabled="isResetDisabled" @click="reset">Сбросить</NButton>
        </div>
      </div>
    </div>

    <div class="content">
      <Audition :range="range" />
      <Actions :range="range" />
      <Incidents :range="range" />
      <Parameters class="mb-4" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.h-cell {
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-right: 1px solid #ccc;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}

.cell {
  font-size: 13px;
  text-align: center;
  border-right: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 4px 8px;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}
</style>
<template>
  <div class="pdf-footer" data-height="220">
    <div class="pdf-page-number text-muted h-16 w-100">
      <div class="flex justify-content-between align-items-end" style="width: 100%;">
        <p style="font-size: 14px;">
          Общество с ограниченной ответственностью<br>
          «Медицина и ядерные технологии»<br>
          факт адрес: 123098, г. Москва,<br>
          пл. Академика Курчатова, д. 1, стр. 159
        </p>
        <p style="font-size: 14px;" class="pr-5">
          {{ pageNumber }}
        </p>
        <p>
          тел. +7 (495) – 739-90-37<br>
          эл. адрес info@mnutech.ru
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageNumber: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss">
.pdf-footer {
  display: flex;
  // justify-content: center;
  align-items: flex-end;
  height: 2.5rem; // 32px
}
</style>

<template>
  <component :is="component" :page-number="props.pageNumber" />
</template>

<script setup>
import { computed } from 'vue';
import Footer from './footers/Footer.vue';
import Footer66 from './footers/Footer66.vue';

const props = defineProps({
  pageNumber: {
    type: Number,
    default: 1
  }
})

const isProd = location.origin === "https://vkkmed.ru"
const isDev = location.origin.includes('dev-front')

const component = computed(() => {
  const organizationId = JSON.parse(localStorage.user).organization.id

  if (isProd) {
    if (organizationId === 66) return Footer66
  }

  if (isDev) {
    // на дев 43 = на прод 66 организация
    if (organizationId === 43) return Footer66
  }

  return Footer
})
</script>

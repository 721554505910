<template>
  <div class="container container-center content">
    <div class="login-container">
      <div class="logo-container">
        <whiteLogo />
        <h5 v-if="isDev">(Обучающая версия)</h5>
      </div>
      <RegistrationForm :type="type" />
    </div>
  </div>
</template>

<script>
import RegistrationForm from "@/components/widgets/custom-components/forms/RegistrationForm";
import whiteLogo from "@/components/widgets/simple-components/logo/whiteLogo";
export default {
  name: 'RegistrationPage',
  components: {
    whiteLogo, RegistrationForm
  },
  data() {
    const isDev = location.host === "dev-front.vkkmed.ru"

    return {
      isDev,
      type: 'invite',
      errorMessage: '',
      name: '',
      email: '',
      password: '',
    }
  },
  beforeMount() {
    if(this.$router.currentRoute.value.params.link) {
      this.type = 'invite';
    } else {
      this.type = 'register';
    }
  },
  mounted() {
    setTimeout(()=> {
      let user = JSON.parse(localStorage.getItem('user'));
      if(user?.id) {
        localStorage.removeItem('user');
      }
    }, 200)
  },
  methods: {
    async registration(e) {
      e.preventDefault();
      let user = {name: this.name, email: this.email, password: this.password};
      this.$store.dispatch("auth/register", user).then(res => {
        if(res.ok == 0) {
          this.errorMessage = res.message;
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
.content {
  padding-top: 60px;
  padding-bottom: 60px;
}
.container {
  z-index: 1;
}
.logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;

  h5 {
    color: #fff;
  }
}
.login-form {
  display: flex;
  flex-direction: column;
}
.login-container {
  display: flex;
  flex-direction: column;
}
</style>
<template>
  <div class="page">
    <div class="logo-container">
      <whiteLogo />
      <h5 v-if="isDev">(Обучающая версия)</h5>
    </div>

    <div class="container">
      <template v-if="activeTab === 'main'">
        <inputWithHeader
          style="padding-bottom: 25px"
          type="text"
          :header="'Введите почту'"
          required
          @keypress.enter="restore"
          @inputHandler="changeEmail"
        />
        <nextButton
          :disabled="checkMail"
          type="success"
          text="Сбросить пароль"
          color="green"
          :loading="loading"
          @clickHandler="restore"
        />
      </template>
      <template v-if="activeTab === 'emailcheck'">
        <div class="text">
          Инструкция по сбросу пароля отправлена на указанный адрес почты. Проверьте почту и следуйте инструкции.
        </div>
      </template>
      <template v-if="activeTab === 'changed'">
        <div class="text">
          Пароль изменён. Вы можете <router-link to="/login"> войти </router-link> в аккаунт, используй новый пароль.
        </div>
      </template>
      <template v-if="activeTab === 'password'">
        <inputWithHeader
          :class="{ warning: password.length > 0 && password.length < 6, success: password.length >= 6 }"
          style="padding-bottom: 25px"
          type="password"
          :header="'Введите новый пароль'"
          required
          @keypress.enter="passwordSend"
          @inputHandler="changePassword"
        />
        <inputWithHeader
          :class="{ warning: passwordConfirmCheck === false, success: passwordConfirmCheck === true }"
          style="padding-bottom: 25px"
          type="password"
          :header="'Повторите пароль'"
          required
          @keypress.enter="passwordSend"
          @inputHandler="changeConfirm"
        />
        <nextButton
          :disabled="!passwordCheck"
          type="success"
          text="Сбросить пароль"
          color="green"
          :loading="loading"
          @clickHandler="passwordSend"
        />
      </template>
    </div>
  </div>
</template>

<script>
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import whiteLogo from '@/components/widgets/simple-components/logo/whiteLogo'
export default {
  name: 'RestorePage',
  components: { inputWithHeader, nextButton, whiteLogo },
  data() {
    const isDev = location.host === "dev-front.vkkmed.ru"

    return {
      isDev,
      activeTab: 'main',
      loading: false,
      email: '',
      password: '',
      passwordConfirm: '',
      token: ''
    }
  },
  computed: {
    passwordCheck() {
      if (this.password.length >= 6) {
        return true
      }
      return false
    },
    passwordConfirmCheck() {
      if (this.passwordConfirm.length > 0) {
        if (this.passwordConfirm === this.password) {
          return true
        } else {
          return false
        }
      }
      return ''
    },
    checkMail() {
      if (this.email.length === 0) {
        return true
      } else {
        return false
      }
    }
  },
  beforeMount() {
    let token = this.$router.currentRoute.value.query.token
    if (token != undefined) {
      this.token = token
      this.activeTab = 'password'
    }
  },
  methods: {
    passwordSend() {
      if (this.passwordCheck) {
        if (this.passwordConfirmCheck) {
          this.loading = true
          this.$api
            .post('auth/change-password', { password: this.password, token: this.token })
            .then((res) => {
              this.loading = false
              if (res.data.ok === 1) {
                this.activeTab = 'changed'
              } else {
                this.$store.dispatch('ALERT_CREATE', { text: res.data.message, type: 'error' })
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          this.$store.dispatch('ALERT_CREATE', { text: 'Пароли не совпадают', type: 'warning' })
        }
      } else {
        this.$store.dispatch('ALERT_CREATE', { text: 'Пароль должен содержать не менее 6 символов', type: 'warning' })
      }
    },
    changeConfirm(val) {
      this.passwordConfirm = val
    },
    changePassword(val) {
      this.password = val
    },
    restore() {
      this.loading = true
      this.$api
        .post('auth/restore', { email: this.email })
        .then((res) => {
          if (res.data.ok === 1) {
            this.$store.dispatch('ALERT_CREATE', { text: res.data.message, type: 'success' })
            this.activeTab = 'emailcheck'
            this.loading = false
          } else {
            this.loading = false
            this.$store.dispatch('ALERT_CREATE', { text: res.data.message, type: 'warning' })
          }
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch('ALERT_CREATE', { text: 'Произошла неизвестная ошибка', type: 'error' })
        })
    },
    changeEmail(val) {
      this.email = val
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  padding: 20px;
  background-color: white;
  z-index: 9;
  box-shadow: 0px 3px 9px rgb(0 0 0 / 13%);
  border-radius: 3px;
  width: 465px;
}

.logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;

  h5 {
    color: #fff;
  }
}

.page {
  height: 100vh;
  width: 100vw;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  display: flex;
  align-items: center;
}
</style>

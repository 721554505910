import api from '@/api'

const endpoint = 'inevent/'
// let checklistStatuses = []
// checklistStatuses[1] = 'На проверке'
// checklistStatuses[2] = 'Проверка завершена'
// checklistStatuses[3] = 'Подписан'

const checklist = {
  state: {
    isLoading: false,
    inevent: {},
    ineventMembers: [],
    ineventList: [],
    ineventStatuses: [],
    ineventTypes: [],
    ineventQueueList: [],
    ineventIssuesList: [],
    ineventFiles: []
  },
  getters: {
    inevent(state) {
      return {
        ...state.inevent,
        members: state.ineventMembers,
        issues: state.ineventIssuesList.map((issue) => ({
          ...issue,
          responsible: state.ineventMembers.find((member) => issue.responsible_id == member.id)
        }))
      }
    },
    ineventMembers(state) {
      return state.ineventMembers
    },
    ineventList(state) {
      return state.ineventList
    },
    ineventStatuses(state) {
      return state.ineventStatuses
    },
    ineventTypes(state) {
      return state.ineventTypes
    },
    ineventQueueList(state) {
      return state.ineventQueueList
    },
    ineventFiles(state) {
      return state.ineventFiles
    }
  },
  mutations: {
    set_inevent_list(state, payload) {
      state.ineventList = payload
    },
    set_inevent_statuses(state, payload) {
      state.ineventStatuses = payload
    },
    set_inevent_types(state, payload) {
      state.ineventTypes = payload
    },
    set_inevent_queue_list(state, payload) {
      state.ineventQueueList = payload
    },
    set_is_loading(state, payload) {
      state.isLoading = payload
    },
    set_inevent(state, payload) {
      state.inevent = payload
    },
    set_inevent_members(state, payload) {
      state.ineventMembers = payload
    },
    add_inevent_issues_list(state, payload) {
      state.ineventIssuesList = payload
    },
    set_inevent_files(state, payload) {
      state.ineventFiles = payload
    }
  },
  actions: {
    INEVENT_DOCUMENTS_GET_ALL(context, payload) {
      return api.get(
        endpoint +
          'get-documents?id=' +
          payload.id +
          '&limit=' +
          payload.limit +
          '&offset=' +
          payload.offset +
          '&category_id=' +
          payload.category_id +
          '&sort_type=' +
          payload.sort.type +
          '&sort_direction=' +
          payload.sort.direction
      )
    },
    INEVENT_GET_LIST(context, payload) {
      const url = `${endpoint}index`

      const params = {
        status_id: payload.status_id,
        type_id: payload.type_id
      }

      context.commit('set_is_loading', true)

      try {
        api
          .get(url, {
            params
          })
          .then((res) => {
            if (res.status === 200) {
              context.commit('set_inevent_list', res.data)
            }
          })
          .finally(() => {
            context.commit('set_is_loading', false)
          })
      } catch (error) {
        console.warn(`INEVENT_GET_LIST: ${error}`)
      }
    },
    INEVENT_GET_STATUSES(context, payload) {
      const url = `${endpoint}statuses`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inevent_statuses', res.data)
          }
        })
      } catch (error) {
        console.warn(`INEVENT_GET_STATUSES: ${error}`)
      }
    },
    INEVENT_GET_TYPES(context, payload) {
      const url = `${endpoint}types`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inevent_types', res.data)
          }
        })
      } catch (error) {
        console.warn(`INEVENT_GET_TYPES: ${error}`)
      }
    },
    INEVENT_SET_QUEUE_LIST(context, payload) {
      const url = `${endpoint}queue`
      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inevent_queue_list', res.data)
          }
        })
      } catch (error) {
        console.warn(`INEVENT_SET_QUEUE_LIST: ${error}`)
      }
    },
    INEVENT_CREATE(context, payload) {
      try {
        return api.post(endpoint, payload)
      } catch (error) {
        console.warn(`INEVENT_SET_QUEUE_LIST: ${error}`)
      }
    },
    INEVENT_GET_BY_ID(context, payload) {
      const url = `${endpoint}${payload}`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inevent', res.data)
          }
        })
      } catch (error) {
        console.warn(`INEVENT_GET_BY_ID: ${error}`)
      }

      context.dispatch('INEVENT_GET_MEMBERS', payload)
      context.dispatch('INEVENT_GET_ISSUES_LIST', payload)
    },
    INEVENT_GET_MEMBERS(context, payload) {
      const url = `${endpoint}members?meeting_id=${payload}`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('set_inevent_members', res.data)
          }
        })
      } catch (error) {
        console.warn(`INEVENT_GET_BY_ID: ${error}`)
      }
    },
    INEVENT_ADD_ISSUE(context, payload) {
      const url = `${endpoint}issues?meeting_id=${payload.meeting_id}`
      try {
        return api.post(url, payload)
      } catch (error) {
        console.warn(`INEVENT_ADD_ISSUE: ${error}`)
      }
    },
    INEVENT_EDIT_ISSUE(context, payload) {
      const url = `${endpoint}issues?meeting_id=${payload.meeting_id}`
      const data = {
        issue_id: payload.issue_id,
        name: payload.name,
        solution: payload.solution,
        responsible_id: payload.responsible_id
      }

      try {
        return api.patch(url, data)
      } catch (error) {
        console.warn(`INEVENT_EDIT_ISSUE: ${error}`)
      }
    },
    INEVENT_GET_ISSUES_LIST(context, payload) {
      const url = `${endpoint}issues?meeting_id=${payload}`

      try {
        api.get(url).then((res) => {
          if (res.status === 200) {
            context.commit('add_inevent_issues_list', res.data)
          }
        })
      } catch (error) {
        console.warn(`INEVENT_GET_ISSUES_LIST: ${error}`)
      }
    },
    INEVENT_DELETE_ISSUE(context, payload) {
      const url = `${endpoint}issues?meeting_id=${payload.meeting_id}`
      try {
        return api.delete(url, {
          data: {
            issue_id: payload.issue_id
          }
        })
      } catch (error) {
        console.warn(`INEVENT_DELETE_ISSUE: ${error}`)
      }
    },
    INEVENT_EDIT(context, payload) {
      const url = `${endpoint}${payload.meeting_id}/edit`

      try {
        return api.patch(url, payload)
      } catch (error) {
        console.warn(`INEVENT_EDIT: ${error}`)
      }
    },
    INEVENT_SET_COMPLETE(context) {
      const { inevent } = context.getters

      const payload = {
        status_id: 3, // завершен
        number: inevent.number,
        type_id: inevent.type_id,
        organization_id: inevent.organization_id,
        datetime: inevent.datetime,
        queue_id: inevent.queue_id,
        chairman_id: inevent.chairman_id,
        deputy_id: inevent.deputy_id,
        secretary_id: inevent.secretary_id,
        members: inevent.members,
        meeting_id: inevent.id
      }

      return context.dispatch('INEVENT_EDIT', payload)
    },
    INEVENT_DELETE(context, payload) {
      const url = `${endpoint}${payload}`
      return api.delete(url)
    },
    INEVENT_DOCUMENTS_UPLOAD_FILE(context, payload) {
      const url = `${endpoint}${payload.meeting_id}/upload`

      return api.post(url, payload.formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    INEVENT_GET_DOCUMENTS(context, payload) {
      return api.get(
        endpoint +
          'get-documents?id=' +
          payload.id +
          '&limit=' +
          payload.limit +
          '&offset=' +
          payload.offset +
          '&category_id=' +
          payload.category_id +
          '&sort_type=' +
          payload.sort.type +
          '&sort_direction=' +
          payload.sort.direction
      )
    },
    // async INEVENT_GET_DOCUMENTS(context, payload) {
    //   const url = `${endpoint}${payload.meeting_id}/files`

    //   const response = await api.get(url)

    //   try {
    //     if (response.status === 200 && response.data.ok === 1) {
    //       const { documentsCategory } = context.getters

    //       const normalizedData = response.data.data.map((doc) => ({
    //         ...doc,
    //         uploaded_at: doc.uploaded_at * 1000,
    //         category: documentsCategory.find((docCategory) => docCategory.id === doc.category_id)
    //       }))
    //       context.commit('set_inevent_files', normalizedData)
    //     }
    //   } catch (error) {
    //     console.warn(`INEVENT_GET_DOCUMENTS: ${error}`)
    //   }
    // },
    async INEVENT_DELETE_FILE(context, payload) {
      const url = `${endpoint}${payload.meeting_id}/files/${payload.file.id}/delete`

      try {
        return api.delete(url)
      } catch (error) {
        console.warn(`INEVENT_DELETE_FILE: ${error}`)
      }
    },
    async INEVENT_EDIT_FILE(context, payload) {
      const url = `${endpoint}${payload.meeting_id}/files/${payload.file_id}/edit`
      const data = {
        name: payload.name,
        category_id: payload.category_id
      }

      return api.patch(url, data)
    }
  }
}
export default checklist

import { h, render } from 'vue'
import { jsPDF } from 'jspdf'
import CheckListReportTemplate from '@/components/pdf/templates/CheckListReportTemplate'
import CheckListProtocolTemplate from '@/components/pdf/templates/CheckListProtocolTemplate'
import ActionsReportTemplate from '@/components/pdf/templates/ActionsReportTemplate'
import { makeDateTime } from '@/helper'

export default class Pdf {
  constructor(config) {
    this.config = {
      orientation: 'p',
      compress: true,
      margins: [5, 10, 10, 20], // мм
      debug: false,
      ...config
    }

    this.formats = {
      p: [210, 297],
      l: [297, 210]
    }

    this.config.format = this.formats[this.config.orientation]

    this.debug = this.config.debug

    // TEMPLATES
    this.templates = {
      CheckListReportTemplate,
      CheckListProtocolTemplate,
      ActionsReportTemplate
    }

    // FONT
    this.fontBase = 'Arial'

    // OTHER
    this.$container = null
    this.pixelRatio = Math.max(window.devicePixelRatio, 1)
    this.contentWidth = 1200 // px
    this.margins = {
      top: this.config.margins[0],
      right: this.config.margins[1],
      bottom: this.config.margins[2],
      left: this.config.margins[3],
      vertical: this.config.margins[0] + this.config.margins[2],
      horizontal: this.config.margins[1] + this.config.margins[3],
      all: this.config.margins
    }
    this.format = {
      width: this.config.format[0],
      height: this.config.format[1]
    }
  }

  async generate(data) {
    console.log('generate', data);
    
    this.data = data
    this.stopRender = false
    this.firstRowIndex = 0
    this.scaleBase = this.format.width / this.contentWidth
    this.saveMode = data.saveMode || 'newwindow'

    await this.#sleep(1000)

    const $template = this.#renderTemplate(data.template, false, true)
    const isFit = this.#checkContentFit($template)
    const pages = []
    let pageNumber = 1

    try {
      if (isFit) {
        $template.style.height = this.#mmToPx(this.format.height) + 'px'
        pages.push($template)
      } else {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const $template = this.#renderTemplate({
            name: data.template.name,
            props: {
              sourceData: data.template.props.sourceData,
              tableData: [],
              pageNumber,
              initialEmpty: data.template.props.initialEmpty
            }
          })

          this.#renderTable($template)

          pages.push($template)

          pageNumber++
          if (this.stopRender) break
        }
      }

      const fileName = data.fileName || `${makeDateTime(new Date())}_report.pdf`
      const doc = await this.#createPdfDoc(pages)
      doc.setProperties({ title: 'PDF report preview' })

      if (this.saveMode === 'newwindow') {
        const tab = window.open(URL.createObjectURL(doc.output('blob')))

        if (!tab || tab.closed || typeof tab.closed === 'undefined') {
          alert('Всплывающее окно заблокировано! Для получения отчета в PDF разрешите показ всплывающих окон для этого сайта и повторите попытку.')
        }
      } else {
        doc.save(fileName)
      }
    } catch (e) {
      alert('Ошибка! Не удалсь сформировать PDF файл :(')
      console.error(e)
    } finally {
      this.#cleanDOM()
    }
  }

  #createPdfDoc(pages = []) {
    const pdf = this.#createInstance()
    const wrapper = document.createElement('div')

    pages.forEach((page) => wrapper.append(page))

    return new Promise((resolve) => {
      pdf.html(wrapper, {
        callback: resolve,
        windowWidth: this.format[0],
        html2canvas: {
          scale: this.scaleBase
        }
      })
    })
  }

  #render(component, props) {
    const $container = this.#createContainer()
    render(h(component, props), $container)

    return [$container.children[0], $container]
  }

  #renderTemplate(template, height = true, globalContainer) {
    console.log('template', template)
    const templateComponent = this.templates[template.name]
    
    const [$el, $container] = this.#render(templateComponent, template.props)

    if (globalContainer) this.$container = $container

    $el.style.fontFamily = this.fontBase
    $el.style.width = this.contentWidth + 'px'
    $el.style.padding = this.margins.all.reduce((p, c) => `${p} ${this.#mmToPx(c)}px`, '')

    if (height) $el.style.height = this.#mmToPx(this.format.height) + 'px'

    if (this.debug) $el.style.border = '1px solid red'

    return $el
  }

  #renderTable($template) {
    const pageFreeSpace = this.#getFreeSpace($template)
    const $originTable = this.$container.querySelector('table')
    const $originThead = $originTable.querySelector('thead')
    const rows = $originTable.querySelectorAll('tbody tr, tfoot tr')
    const $table = $originTable.cloneNode()
    let heightSum = 0 // мм

    if ($originThead) {
      $table.append($originThead.cloneNode(true))
      heightSum += this.#pxToMm($originThead.clientHeight) / this.pixelRatio
    }

    heightSum += 20

    for (let i = 0; i < rows.length; i++) {
      const $row = rows[i]
      const rowHeight = this.#pxToMm($row.clientHeight)

      if (heightSum + rowHeight >= pageFreeSpace) break
      if (i % 2 && !$row.classList.contains('no-bg')) $row.className = 'bg-gray-100'

      heightSum += rowHeight
      $table.append($row)

      if (i + 1 >= rows.length) this.stopRender = true
    }

    this.#log('renderTable()', heightSum, pageFreeSpace)

    $table.querySelectorAll('tr:last-child').forEach(($row) => {
      const borderPos = $row.classList.contains('border-top') ? 'borderTop' : 'borderBottom'
      $row.querySelectorAll('td').forEach((item) => (item.style[borderPos] = '1px solid #E0E0E0'))
    })

    this.#fixTableSpaces($table)
    $template.querySelector('.table-section').append($table)
  }

  #createContainer() {
    const $container = document.createElement('div')
    $container.className = 'pdf-container'
    $container.style.position = 'fixed'
    $container.style.visibility = 'hidden'

    document.body.append($container)

    return $container
  }

  #cleanDOM() {
    document.querySelectorAll('.pdf-container').forEach((item) => item.remove())
  }

  #createInstance() {
    const doc = new jsPDF({
      format: this.config.format,
      orientation: this.config.orientation,
      compress: this.config.compress
    })
    doc.addFont(`/assets/fonts/${this.fontBase}-Regular.ttf`, this.fontBase, 'normal', 400)
    doc.addFont(`/assets/fonts/${this.fontBase}-Bold.ttf`, this.fontBase, 'normal', 700)
    doc.setFont(this.fontBase)

    return doc
  }

  #pxToMm(px) {
    return Math.round(px * this.scaleBase)
  }

  #mmToPx(mm) {
    return Math.round(mm / this.scaleBase)
  }

  #getFreeSpace($el) {
    const items = $el.querySelectorAll('[data-height]')
    let itemsHeight = 0

    items.forEach(($item) => {
      itemsHeight += parseInt($item.getAttribute('data-height'))
    })

    this.#log('getFreeSpace()', this.#pxToMm(itemsHeight))

    return this.format.height - this.#pxToMm(itemsHeight)
  }

  #fixTableSpaces($table) {
    $table.querySelectorAll('td').forEach(($td) => {
      $td.innerHTML = `<div style="padding: 6px 12px 8px 12px;">${$td.innerHTML}</div>`
    })
  }

  #checkContentFit($el) {
    this.#log('checkContentFit()', this.#mmToPx(this.format.height) >= $el.clientHeight)

    return this.#mmToPx(this.format.height) >= $el.clientHeight
  }

  #sleep(ms) {
    return new Promise((res) => setTimeout(res, ms))
  }

  #log(...args) {
    if (this.debug) console.log(...args)
  }
}

import { ref } from 'vue'

import api from '@/api'
import moment from 'moment'

const useAuditionReport = () => {
  const fetchedCount = ref(0)
  const items = ref([])
  const params = ref()

  const fetch = async (prms) => {
    if (prms && !params.value) {
      params.value = prms
    }

    try {
      const res = await api.get('/checklists/get-organization-checklists', { params: params.value })

      if (res.status === 200) {
        // fetchedCount.value += params.value.limit
        // params.value.offset = params.value.offset += params.value.limit

        // items.value = [
        //   ...items.value,
        //   ...res.data.data
        // ]
        
        // if (fetchedCount.value < Number(res.data.count)) {
        //   fetch()
        // } else {
        //   return groupItems(params)
        // }
        items.value = res.data.data
        return groupItems(params)
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function groupItems(params) {
    const { start_date, stop_date } = params.value
  
    // 1. Плановые проверки
    const d1 = items.value.filter((el) => el.status.id === 2 || el.status.id === 3).length // "Проверка завершена", "Подписан" 

    // 2. Проведено плановых проверок, за период
    const d2 = items.value.filter((el) => el.status.id === 2).length
    // 3. Количество проверок, запланированных за период
    // ???

    // 4. Процент выполнения плана проверок за период
    // ПЛАН(status=4) и ПРОВЕРКА ПРОВОДИТСЯ(status=1)
    // только ПЛАНОВАЯ ВНУТРЕННЯЯ и ПЛАН или ПЛАНОВАЯ ВНУТРЕННЯЯ и ПРОВОДИТСЯ ПРОВЕРКА
    // дата начала plan_date
    const d4 = items.value.filter((el) => el.assurance.id === 1 && [1,4].includes(el.status.id) && (start_date <= el.plan_date * 1000) && (el.plan_date * 1000 < stop_date) )
    const d4val = (100 - (d4.length / items.value.length * 100)).toFixed(0)

    // 5. Кол-во плановых проверок, проведенных с нарушением сроков (больше 10 дней)
    const d5 = items.value.filter((el) => {
      // @TODO: учитывать рабочие дни
      const daysDiff = moment(el.finish_date).diff(moment(el.plan_date * 1000), 'days')
      if (daysDiff > 14) return el
      return
    })

    // 6. ... выявлены нарушения
    const d6 = items.value.filter((el) => el.requirements.some((req) => req.status_id === 3))

    // 7. Кол-во выявленных несоответствий (нарушений), в ходе плановых проверок
    const { data: { data: processes } } = await api.get('/process/formatted')
    
    const {
      requiredUnpassed,
      optionalUnpassed
    } = processes.reduce(
      (p, c) => {
        p.requiredUnpassed += c?.required_requirements_count?.unpassed || 0
        p.optionalUnpassed += c?.optional_requirements_count?.unpassed || 0
        return p
      },
      {
        requiredUnpassed: 0,
        optionalUnpassed: 0
      }
    )
    const d7 = requiredUnpassed + optionalUnpassed

    // 8. Внеплановые
    const d8 = items.value.filter((el) => el.assurance.id === 2).length

    return {
      d1,
      d2,
      d4val,
      d5,
      d6,
      d7,
      d8
    }

  }

  const fetchFormatedProcess = async () => {
    const { data: { data: medtypes } } = await api.get('/organization/get-organization-medtypes')

    const promises = medtypes.map(medtype => {
      return api.get('/process/formatted', {
        params: {
          medtype_id: medtype.id
        }
      })
    });

    const arrayOfResponseData = (await Promise.all(promises)).map((response, index) => ({
      medtype: medtypes[index],
      data: response.data.data,
    }))
    // console.log('arrayOfResponseData', arrayOfResponseData);
    
    return arrayOfResponseData
  }

  return {
    fetch,
    fetchFormatedProcess
  }
}

export default useAuditionReport

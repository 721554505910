<template>
  <!-- <div class="checklist-report-template">
    <HeaderPdf />

    <div class="table-section">
      <TablePdf :columns="columns" :data="computedTableData" />
    </div>

    <FooterPdf class="mt-auto" :page-number="pageNumber" />
  </div> -->
  <div class="checklist-report-template">123456</div>
</template>
<script>
import HeaderPdf from '@/components/pdf/common/HeaderPdf'
import FooterPdf from '@/components/pdf/common/FooterPdf'
import TablePdf from '@/components/pdf/common/TablePdf'
import { makeDate, makeDateTime } from '@/helper'

export default {
  components: { TablePdf, FooterPdf, HeaderPdf },
  props: {
    sourceData: {
      type: Object,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    pageNumber: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      columns: [
        {
          key: 'requirements',
          title: 'Требования',
          width: '33%'
        },
        {
          key: 'discrepancy',
          title: 'Выявленные проблемы/несоответствия',
          width: '33%'
        },
        {
          key: 'events',
          title: 'Мероприятия'
        }
      ]
    }
  },
  computed: {
    reportNumber: (vm) => vm.sourceData?.number?.replace('_', '-'),
    planDateTime: (vm) => makeDateTime(vm.sourceData?.plan_date * 1000 || vm.sourceData?.create_date),
    finishDate: (vm) => makeDate(vm.sourceData?.finish_date),
    finishDateTime: (vm) => makeDateTime(vm.sourceData?.finish_date),
    requirementsCount: (vm) => vm.sourceData.requirements.length,
    correspondenceCount: (vm) => vm.sourceData.requirements.filter((item) => item.status_id === 2).length,
    discrepanciesCount: (vm) => vm.sourceData.requirements.filter((item) => item.status_id === 3).length,
    notApplicableCount: (vm) => vm.sourceData.requirements.filter((item) => item.status_id === 4).length,
    computedTableData() {
      console.log('this.tableData', this.tableData);
      
      return this.tableData?.map((item) => {
        return {
          requirements: item?.requirement?.text,
          discrepancy: item?.commentary,
          events: item?.action?.text
        }
      })
    }
  },
  methods: {
    toPercent(to, from) {
      return Math.round((100 / from) * to)
    }
  }
}
</script>

<style lang="scss">
.checklist-report-template {
  display: flex;
  flex-direction: column;

  &__order-box {
    padding: 1rem;
    border: 1px solid #000;
  }
}
</style>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { NDivider } from 'naive-ui';
import moment from 'moment'

import NextTable from '@/components/widgets/simple-components/table/NextTable'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import { toPercent } from '@/helper'

import useAuditionReport from '@/composables/useAuditionReport'

const props = defineProps({
  range: {
    type: Array,
    default: () => ['', ''],
  }
})

const auditionReport = useAuditionReport()

const isLoading = ref(true)
const data = ref(null)

const isLoadingTablesData = ref(true)
const tablesData = ref(null)

const columns = [
  {
    key: 'text',
    title: 'Раздел',
    width: '30%'
  },
  {
    key: 'requirements',
    title: 'Применимых пунктов требований',
    center: true
  },
  {
    key: 'passed',
    title: 'Соответствий пунктам',
    center: true
  },
  {
    key: 'passedPart',
    title: 'Доля соответствий',
    center: true,
  },
  {
    key: 'unpassed',
    title: 'Несоответствий',
    center: true
  },
  {
    key: 'allEvents',
    title: 'Запланированных мероприятий',
    center: true
  },
  {
    key: 'completedEvents',
    title: 'Выполненных мероприятий',
    center: true
  },
  {
    key: 'resultEvents',
    title: 'Результативность мероприятий',
    center: true
  }
]

const params = {
  offset: 0,
  limit: 1000,
  sort_type: 'id',
  sort_direction: 'desc',
  status: '',
  date_from: '2024-01-01',
  date_to: '2024-12-31',
};

onMounted(() => {
  fetchData()
  fetchFormatedProcess()
})

const fetchData = async () => {
  isLoading.value = true
  data.value = await auditionReport.fetch(params)
  isLoading.value = false
}

const fetchFormatedProcess = async () => {
  const result = await auditionReport.fetchFormatedProcess(params)

  tablesData.value =
    result.map(({ medtype, data }) => ({
      medtype,
      data: data.map((item) => ({
        id: item.id,
        text: item.text,
        requirements: item.requirements_count.all,
        passed: item.requirements_count.passed,
        passedPart:
          (toPercent(item.requirements_count.all, item.requirements_count.passed) || 0),
        unpassed: item.requirements_count.unpassed,
        allEvents: item.events_count.all,
        completedEvents: item.events_count.completed,
        resultEvents: (toPercent(item.events_count.all, item.events_count.completed) || 0) + '%'
      })).filter(item => item.allEvents > 0) || []
    }))

  isLoadingTablesData.value = false
}

watch(
  () => props.range,
  (newRange) => {
    if (newRange[0] && newRange[1]) {
      params.date_from = moment(new Date(newRange[0]).getTime()).format('YYYY-MM-DD');
      params.date_to = moment(new Date(newRange[1]).getTime()).format('YYYY-MM-DD');
      fetchData();
      fetchFormatedProcess();
    }
  },
  { immediate: false }
)
</script>

<template>
  <div v-if="isLoading" class="flex">
    <SpinLoader style="display: inline-block;" class="w-8" />
    <p class="h5">Загрузка данных раздела Аудит...</p>
  </div>

  <div v-if="data && !isLoading" class="mb-5">
    <NDivider><div style="font-size: 28px;">Отчёт Аудит</div></NDivider>
    <div class="mb-4">
      <div class="flex" style="gap: 5px;" :style="{ borderTop: '1px solid #ccc' }">
        <span class="px-2 py-1 h-cell" style="width: 80%;">Наименование показателя</span>
        <span class="px-2 py-1 h-cell" style="width: 20%;">Количество</span>
      </div>
      <div class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;" :style="{ borderTop: '1px solid #ccc' }">
        <span class="cell" style="width: 80%; text-align: left; font-size: 16px;">Проведено плановых проверок, за период</span>
        <span class="cell" style="width: 20%; font-size: 16px;">{{ data.d1 }}</span>
      </div>
      <!-- <div class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;">
        <span class="cell" style="width: 80%; text-align: left; font-size: 16px;">Количество проверок, запланированных на период</span>
        <span class="cell" style="width: 20%; font-size: 16px;">{{ data.d2 }}</span>
      </div> -->
      <div class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;">
        <span class="cell" style="width: 80%; text-align: left; font-size: 16px;">Процент выполнения плана проверок на период</span>
        <span class="cell" style="width: 20%; font-size: 16px;">{{ data.d4val }}%</span>
      </div>
      <div class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;">
        <span class="cell" style="width: 80%; text-align: left; font-size: 16px;">Проведено внеплановых проверок, за период</span>
        <span class="cell" style="width: 20%; font-size: 16px;">{{ data.d8 }}</span>
      </div>
      <div class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;">
        <span class="cell" style="width: 80%; text-align: left; font-size: 16px;">Количество проверок, проведенных с нарушением сроков (более 10 дней)</span>
        <span class="cell" style="width: 20%; font-size: 16px;">{{ data.d5.length }}</span>
      </div>
      <!-- <div class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;">
        <span class="cell" style="width: 80%; text-align: left; font-size: 16px;">Количество плановых проверок, в ходе которых выявлены нарушения</span>
        <span class="cell" style="width: 20%; font-size: 16px;">{{ data.d6.length }}</span>
      </div> -->
      <div class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;">
        <span class="cell" style="width: 80%; text-align: left; font-size: 16px;">Количество выявленных несоответствий (нарушений), в ходе проверок</span>
        <span class="cell" style="width: 20%; font-size: 16px;">{{ data.d7 }}</span>
      </div>
    </div>

    <div>
      <div v-for="item in tablesData" :key="JSON.stringify(item)" class="mb-3">
        <div v-if="item.data.length">
          <h6>{{ item.medtype.text }}</h6>
          <NextTable :columns="columns" :table-data="item.data" striped>
            <template #text="{ row, value }">
              <RouterLink :to="`/process/${row.id}`" class="text-gray-800">{{ value }}</RouterLink>
            </template>
            <template #passedPart="{ row, value }">
              <span :class="{'text-danger font-weight-bold': value <= 70 }">
                {{ value }}%
              </span>
            </template>
          </NextTable>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.h-cell {
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-right: 1px solid #ccc;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}

.cell {
  font-size: 13px;
  text-align: center;
  border-right: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 4px 8px;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}
</style>
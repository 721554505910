<template>
  <div class="user-layout">
    <Header />
    <div class="user-layout__container">
      <Sidebar class="hide-on-print" />
      <div class="content" :class="{ headerOpened: $store.getters['headerOpened'] }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/layout/Header'
import Sidebar from '@/components/layout/Sidebar'

export default {
  name: 'UserLayout',
  components: {
    Header,
    Sidebar
  },
  computed: {},
  mounted() {
    this.redirectByTarif()
  },
  methods: {
    redirectByTarif() {
      const isBaseTarif = this.$store.getters.currentUser?.organization?.tarif?.name === 'Базовый'
      const isStartTarif = this.$store.getters.currentUser?.organization?.tarif?.name === 'Стартовый'

      if (isBaseTarif && !localStorage.redirectedForBaseTarif) {
        this.$router.push('/organization/tariffs')
        localStorage.setItem('redirectedForBaseTarif', '1')
      }

      if (isStartTarif && !localStorage.redirectedForStartTarif) {
        this.$router.push('/videos')
        localStorage.setItem('redirectedForStartTarif', '1')
      }
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  transition: all 0.3s;
}
.user-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.user-layout__container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (min-width: 320px) {
  .content {
    padding: 100px 10px 60px 10px;
  }
}

@media (min-width: 900px) {
  .content {
    padding: 100px 20px 60px 20px;
  }
}

@media (min-width: 1280px) {
  .content {
    padding: 100px 60px 60px 100px;
  }
}

@media (min-width: 1920px) {
  .headerOpened.content {
    width: calc(100% - 200px);
    padding-left: 80px;
  }
  .headerOpened {
    margin-left: 200px;
  }

  .user-layout__container {
    justify-content: flex-start;
  }
}

@media (min-width: 2300px) {
  .headerOpened {
    margin-left: auto;
    margin-right: auto;
  }
}

@media print {
  .hide-on-print {
      display: none;
  }
}
</style>

<template>
  <div v-if="isLoadingActions" class="flex">
    <SpinLoader style="display: inline-block;" class="w-8" />
    <p class="h5">Загрузка данных раздела Мероприятия...</p>
  </div>

  <div v-if="!isLoadingActions && actionsReportData" class="mb-5">
    <NDivider><div style="font-size: 28px;">Отчёт Мероприятия</div></NDivider>

    <div class="mb-4">
      <h5>Мероприятия по типу за период</h5>
      <div v-for="data in Object.entries(actionsReportTypesCount)" :key="data">
        {{ data[0] }}: <strong>{{ data[1] }}</strong>
      </div>
    </div>

    <div class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;" :style="{ borderTop: '1px solid #ccc' }">
      <span class="px-2 py-1 h-cell" style="width: 30%;">Мероприятия</span>
      <span class="px-2 py-1 h-cell" style="width: 8%;">
        Дата начала
      </span>
      <span class="px-2 py-1 h-cell" style="width: 8%;">
        Плановая дата закрытия
      </span>
      <span class="px-2 py-1 h-cell" style="width: 8%;">
        Дата закрытия
      </span>
      <span class="px-2 py-1 h-cell" style="width: 14%;">
        Ответственный
      </span>
      <span class="px-2 py-1 h-cell" style="width: 10%;">
        Тип мероприятия
      </span>
      <span class="px-2 py-1 h-cell" style="width: 10%;">
        Объект учета
      </span>
      <span class="px-2 py-1 h-cell" style="width: 10%;">
        Статус
      </span>
    </div>
    <div v-for="[key, val] in Object.entries(actionsReportData)" :key="key" class="mb-1">
      <h6 class="text-center my-2">Направление "{{ key }}"</h6>
      <div v-for="[k, cell] in Object.entries(val)" :key="k">
        <p class="my-2" style="font-weight: bold;">{{ k }}</p>
        <div v-for="(row, index) in cell" :key="row" class="flex" style="gap: 5px; border-bottom: 1px solid #ccc;" :style="{ borderTop: index === 0 ? '1px solid #ccc' : '' }">
          <span class="cell" style="width: 30%; text-align: left">{{ row.text }}</span>
          <span class="cell" style="width: 8%;">{{ moment(row.start_date).format('DD.MM.YYYY') }}</span>
          <span class="cell" style="width: 8%;">{{ moment(Number(row.plan_date)).format('DD.MM.YYYY') }}</span>
          <span class="cell" style="width: 8%;">{{ row.finish_date ? moment(Number(row.finish_date)).format('DD.MM.YYYY') : '' }}</span>
          <span class="cell" style="width: 14%;">{{ row.responsible?.name }}</span>
          <span class="cell" style="width: 10%;">{{ row.type?.text }}</span>
          <span class="cell" style="width: 10%;">{{ row.object?.text }}</span>
          <span class="cell" style="width: 10%; ">{{ row.status?.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import moment from 'moment';
import { NDivider } from 'naive-ui';

import useActionsReport from '@/composables/useActionsReport'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'

const props = defineProps({
  range: {
    type: Array,
    default: () => ['', ''],
  }
})

const actionsReport = useActionsReport()

const isLoadingActions = ref(true)
const actionsReportData = ref(null)
const actionsReportTypesCount = ref(null)

onMounted(() => {
  fetchData()
})

const params = {
  offset: 0,
  limit: 99999,
  sort_type: 'id',
  sort_direction: 'desc',
  date_from: '2024-01-01',
  date_to: '2024-12-31',
};

const fetchData = async () => {
  isLoadingActions.value = true

  actionsReportData.value = await actionsReport.fetch(params)
  actionsReportTypesCount.value = actionsReport.getCountByTypes()

  isLoadingActions.value = false
}

watch(
  () => props.range,
  (newRange) => {
    if (newRange[0] && newRange[1]) {
      params.date_from = moment(new Date(newRange[0]).getTime()).format('YYYY-MM-DD');
      params.date_to = moment(new Date(newRange[1]).getTime()).format('YYYY-MM-DD');
      fetchData();
    }
  },
  { immediate: false }
)
</script>

<style scoped lang="scss">
.h-cell {
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-right: 1px solid #ccc;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}

.cell {
  font-size: 13px;
  text-align: center;
  border-right: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 4px 8px;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}
</style>
import { ref } from 'vue'

import api from '@/api'
import moment from 'moment'

const useIncidentsReport = () => {
  const fetchedCount = ref(0)
  const items = ref([])
  const itemsGroupedByProcess = ref({})
  const params = ref()

  const fetch = async (prms) => {
    if (prms && !params.value) {
      params.value = prms
    }
    // console.log('useIncidentsReport prms', prms);

    try {
      const res = await api.get('/inevent', { params: params.value })

      if (res.status === 200) {
        // fetchedCount.value += params.value.limit
        // params.value.offset = params.value.offset += params.value.limit

        items.value = res.data

        return groupItems(params)
        // if (fetchedCount.value < Number(res.data.count)) {
        //   fetch()
        // } else {
        //   return groupItems(params)
        // }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function groupItems(params) {
    // const { start_date, stop_date } = params.value
    
    const groupedDataByDepartment = items.value.reduce((acc, item) => {
      const departmentName = item.department?.name || 'Без отдела';
    
      // Создаём уникальный ключ для объединения
      const uniqueKey = [
        item.medType?.text,
        item.process?.text,
        item.actionCategory?.name
      ].join('|');

      // Если отдела ещё нет в acc, создаём запись
      if (!acc[departmentName]) {
        acc[departmentName] = {};
      }
    
      // Если объекта с таким уникальным ключом ещё нет, добавляем
      if (!acc[departmentName][uniqueKey]) {
        acc[departmentName][uniqueKey] = {
          ...item,
          count: 1,
          countNew: item.status === 1 ? 1 : 0,
          countInProcess: item.status === 2 ? 1 : 0,
          countClosed: item.status === 3 ? 1 : 0,
        };
      } else {
        // Если объект с таким ключом уже есть, обновляем счётчики
        acc[departmentName][uniqueKey].count += 1;
        if (item.status === 1) acc[departmentName][uniqueKey].countNew += 1;
        if (item.status === 2) acc[departmentName][uniqueKey].countInProcess += 1;
        if (item.status === 3) acc[departmentName][uniqueKey].countClosed += 1;
      }
    
      return acc;
    }, {});

    const result = Object.entries(groupedDataByDepartment).map(([department, items]) => ({
      department,
      items: Object.values(items).sort((a, b) => {
        // Сортировка по count (убывание)
        if (b.count !== a.count) {
          return b.count - a.count;
        }
        // Если count равны, сортируем по process.number (возрастание)
        const numA = parseFloat(a.process?.number || 0);
        const numB = parseFloat(b.process?.number || 0);
        return numA - numB;
      })
    }));
    
    return result
  }

  const getGroupedByProcessText = () => {
    const result = items.value.reduce((acc, item) => {
      const processText = item.process?.text;
      const processNumber = item.process?.number;
  
      if (!processText) return acc;
  
      // Если группы по process.text еще нет, создаем ее
      if (!acc[processText]) {
        acc[processText] = {
          processNumber: '',
          medType: '',
          new: 0,
          inProcess: 0,
          closed: 0,
          total: 0
        };
      }
  
      // Увеличиваем счетчики на основе статуса
      if (item.status === 1) acc[processText].new++;
      if (item.status === 2) acc[processText].inProcess++;
      if (item.status === 3) acc[processText].closed++;
  
      acc[processText].processNumber = processNumber
      acc[processText].total++;
      acc[processText].medType = item.medType.text
  
      return acc;
    }, {});

    return result
  }

  return {
    fetch,
    getGroupedByProcessText,
  }
}

export default useIncidentsReport
